@import "../../../../assets/styles/common/variables";
@import "../../../../assets/styles/common/type-sizing";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.usp-slide {
  border-right: 1px solid $mercury;

  &[data-usp='last'] {
    border-right: none;
  }

  @include media-breakpoint-down(md) {
    border-right: none;
  }
}
